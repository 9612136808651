<template>
  <div>
    <BaseHeader :title="'Checkout'"></BaseHeader>
    <BaseLoading style="min-height: 400px;height: 90vh;" v-if="$store.state.loading"></BaseLoading>

    <div v-else class="card mt-2 border-0 bg-white shadow">
      <div class="card-body">
        <div class="row" v-if="Object.keys(order).length > 0">
          <div class="col-12 text-left" v-if="order.is_paid">
            <div class="media alert alert-success">
              <div class="mr-3">
                <a
                  href="#"
                  class="btn bg-transparent border-success text-success rounded-round border-2 btn-icon"
                >
                  <i class="icon-checkmark3"></i>
                </a>
              </div>

              <div class="media-body">
                Your order is paid!
                <div class="text-muted">We've received your order payment!</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-8 text-left" sm="6">
            <h1
              class="font-weight-bold text-capitalize order-title"
              style="font-size: 1.6rem"
            >Order ID #{{ order.id || '' }}</h1>
            <p>
              <strong>{{ order.subject_area_text || '' }}</strong> |
              <strong>{{ order.academic_level_text || '' }}</strong> |
              <strong>{{ order.pages || '' }} page(s)</strong>
            </p>
          </div>

          <div class="col-12 col-sm-6 col-md-4" sm="6">
            <div class="m-0 text-left">
              <span class="font-weight-bold">
              </span>
              <!-- <a href="#" class="btn btn-outline bg-primary btn-icon text-primary border-primary border-2 rounded-round font-weight-bold">
									            Order Amount: ${{order.cost || ''}}
              </a>-->
              <div class="btn-group my-1">
                <button
                  class="btn btn-success"
                  data-toggle="modal"
                  data-target="#tipModal"
                >Tip Writer</button>
                <router-link
                  :to="{ name: 'Order', params: { id: order.id }}"
                  class="btn btn-primary mx-1"
                >View Order</router-link>
                <button class="btn btn-warning">Edit</button>
                <a href="#is-payment-section" class="btn ml-1 bg-teal">Pay</a>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-sm-6 table-responsive">
                <table
                  class="table table-stripped text-left bg-light"
                  style="font-size: 13px !important"
                >
                  <tr>
                    <td>Topic</td>
                    <td colspan="2">: {{ order.title || '' }}</td>
                  </tr>
                  <tr>
                    <td>Academic Level</td>
                    <td colspan="2">: {{ order.academic_level_text || '' }}</td>
                  </tr>
                  <tr>
                    <td>Subject</td>
                    <td colspan="2">: {{ order.subject_area_text || '' }}</td>
                  </tr>
                  <tr>
                    <td>Type of Paper</td>
                    <td colspan="2">: {{ order.document_type_text || '' }}</td>
                  </tr>
                  <!-- <tr>
                                    <td>Title</td>
                                    <td colspan="2">: {{ order.title || '' }}</td>
                  </tr>-->
                  <tr>
                    <td>Paper Details</td>
                    <td>
                      <pre>{{ order.description || '' }}</pre>
                    </td>
                  </tr>
                  <tr>
                    <td>Paper Format / Style</td>
                    <td colspan="2">: {{ order.style || '' }}</td>
                  </tr>
                  <tr>
                    <td>First draft deadline</td>
                    <td>: {{ order.deadline || '' }}</td>
                  </tr>

                  <tr>
                    <td>Number of sources</td>
                    <td colspan="2">: {{ order.no_sources || '0' }}</td>
                  </tr>
                  <tr>
                    <td>Writer Level</td>
                    <td colspan="2">: {{ order.writer_level_desc || '0' }}</td>
                  </tr>

                  <tr>
                    <td>Additional Services</td>
                    <td colspan="2">
                      <table class="table table-borderless">
                        <tr
                          v-for="(item, index) in order.additional_services_text"
                          :key="index + 'add'"
                        >
                          <td>{{ item[0] }}</td>
                          <hr />
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>Spacing</td>
                    <td colspan="2">: {{ order.spacing_text || '' }}</td>
                  </tr>

                  <tr>
                    <td>page(s)</td>
                    <td colspan="2">: {{order.pages || ''}}</td>
                  </tr>
                  <tr>
                    <td>Slide(s)</td>
                    <td colspan="2">: {{order.slides || ''}}</td>
                  </tr>
                  <tr>
                    <td>Chart(s)</td>
                    <td colspan="2">: {{order.charts || ''}}</td>
                  </tr>
                  <tr>
                    <td>Date Ordered</td>
                    <td>: {{order.date_placed || ''}}</td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="(extra, index) in order.extras"
                    :key="index + 'oextras'"
                    :class="extra.paid !== '0' ? 'alert-success' : 'alert-danger'"
                  >
                    <td>{{extra.name || ""}}</td>
                    <td>: ${{extra.cost || 0}}</td>
                    <td>
                      <button v-if="extra.paid !== '0'" class="ml-1 btn btn-sm bg-teal">Paid</button>
                      <span v-else class="d-flex justify-content-center align-items-center">
                        <button @click="deleteExtra(extra.id)" class="mx-1 btn btn-sm btn-danger">
                          Delete
                          <span class="icon-trash"></span>
                        </button>
                        Unpaid
                      </span>
                    </td>
                  </tr>
                  <tr class="alert alert-info">
                    <td class="font-weight-bold">Extras Total</td>
                    <td class="font-weight-bold">: ${{ order.extras_total || 0 }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Total Order Cost</td>
                    <td class="font-weight-bold">: ${{ order.cost }}</td>
                    <td></td>
                  </tr>
                  <tr class="alert alert-success">
                    <td class="font-weight-bold">Paid</td>
                    <td class="font-weight-bold">: ${{ order.total_paid || 0 }}</td>
                    <td></td>
                  </tr>
                  <tr class="alert alert-danger">
                    <td class="font-weight-bold">Balance</td>
                    <td class="font-weight-bold">: ${{ order.balance || 0 }}</td>
                    <td></td>
                  </tr>
                </table>
              </div>

              <div class="col-sm-6 text-left">
                <h1 class="border-bottom mb-0 mt-3" id="is-payment-section">Total Cost</h1>
                <h5 class="my-0 p-1 bg-light">
                  <span>Order Price</span>
                  <strong class="float-right">{{ order.cost || '' }} USD</strong>
                </h5>

                <h5 class="my-0 p-1 bg-light">
                  <span>Total Extras</span>
                  <strong class="float-right">{{ order.extras_total || 0 }} USD</strong>
                </h5>

                <h5 class="my-0 p-1" style="background-color: #b3d6b5;">
                  <span>Paid</span>
                  <strong class="float-right">{{ order.total_paid || 0 }} USD</strong>
                </h5>
                <h5 class="my-0 p-1" style="background-color: #eaafaf;">
                  <span>Balance</span>
                  <strong class="float-right">{{ order.balance || 0 }} USD</strong>
                </h5>

                <div class="my-2"></div>

                <div v-if="order.is_paid">
                  <div class="media alert alert-success">
                    <div class="mr-3">
                      <a
                        href="#"
                        class="btn bg-transparent border-success text-success rounded-round border-2 btn-icon"
                      >
                        <i class="icon-checkmark3"></i>
                      </a>
                    </div>

                    <div class="media-body">
                      Your order is paid!
                      <div class="text-muted">We've received your order payment!</div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div v-if="payment_vars">
                    <div class="alert alert-info">
                      <p>
                        <i class="fa fa-exclamation-triangle"></i>
                        <span v-html="payment_vars.message || '' "></span>
                      </p>
                    </div>

                    <div class="my-2">
                      <a
                        :href="payment_vars.checkout || '#'"
                        class="btn btn-outline-success btn-lg m-1"
                      >
                        <span class="text-uppercase font-weight-bold">Card Checkout</span>
                        <img
                          class="d-none d-sm-inline"
                          width="100"
                          src="../../assets/global_assets/images/payment.png"
                          alt="Credit and debit card"
                        />
                      </a>
                      <button
                        class="btn btn-success btn-lg m-1"
                        @click="walletPaymentUrl"
                      >Pay From Wallet</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Order",
  components: {},
  computed: {
    ...mapState("order", ["order"]),
    ...mapState("payment", ["paymentForm", "payment_vars"]),
  },
  data() {
    return {
      is_loading: false,
    };
  },
  async mounted() {
    let paymentUrl = `${process.env.VUE_APP_API_BASE_URL}/Checkout/GetUrl`;
    this.$store.commit("payment/setPaymentUrl", paymentUrl);
    this.$store.state.loading = true;
    await this._getOrder(this.$route.params.id);
    this.paymentForm.type = "order";
    (this.paymentForm.amount = this.order.cost),
      (this.paymentForm.reference = this.order.id);
    this.paymentForm.call_back = document.location.href;
    await this._getPaymentUrl(this.paymentForm);
    await this.$nextTick();
  },

  methods: {
    ...mapActions("order", ["_getOrder", "deleteExtras"]),
    ...mapMutations("order", ["unsetOrder"]),
    ...mapActions("payment", ["_getPaymentUrl"]),
    ...mapMutations("payment", ["_setPaymentForm", "setPaymentUrl"]),

    walletPaymentUrl() {
      let paymentUrl = `${process.env.VUE_APP_API_BASE_URL}/Checkout/WalletCheckout`;
      this.$store.commit("payment/setPaymentUrl", paymentUrl);
      this.paymentForm.type = "order";
      this.paymentForm.reference = this.order.id;
      this.is_loading = true;
      this._getPaymentUrl(this.paymentForm)
        .then((res) => {
          console.log(res);
          if (res.data.Type) {
            this.$notify({
              title: "Success",
              text: res.data.Message || "Payment successiful!",
              style: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              text: res.data.Message || "Payment could not be processed!",
              style: "danger",
            });
          }

          this.is_loading = false;
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
          this._getOrder(this.$route.params.id);
        });
    },
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },

  beforeDestroy() {
    this.unsetOrder();
  },
};
</script>
